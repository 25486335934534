import React from 'react'
import styled from 'styled-components'

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }
`;

const FullScreenOverlay = ({ isOpen, onClose, children }) => {
  // If not open, return null
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    // Close only if clicking directly on the overlay background
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <OverlayContainer onClick={handleOverlayClick}>
      <ModalContent>
        <CloseButton onClick={onClose} aria-label="Close overlay">
          X
        </CloseButton>
        {children}
      </ModalContent>
    </OverlayContainer>
  );
};

export default FullScreenOverlay;
