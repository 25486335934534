import React from "react"

import HomePageHero from "../components/HomePageHero"
import OurServices from "../components/OurServices"
import Layout from "../components/layout"
import WhyChooseUs from "../components/WhyChooseUs"
import AboutUs from "../components/AboutUs"
import Seo from "../components/seo"
import HolidayHoursOverlay from "../components/HolidayHoursOverlay"

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <HomePageHero />
      <OurServices />
      <WhyChooseUs />
      <AboutUs />
      <HolidayHoursOverlay />
    </Layout>
  )
}

export default IndexPage
