import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import FullScreenOverlay from './FullScreenOverlay'

const StyledImg = styled.div`
  height: 70vh;
  width: 50vw;
  min-width: 300px;
  max-width: 500px;
  padding-top: 20px;
  @media (max-width: 1024px) {
    width: 100vw;
    height: 100%;
    max-width: 100%;
  }
`

const HolidayHoursOverlay = () => {
  const [overlayOpened, setOverlayOpened] = React.useState(true)
  const { holidayHours } = useStaticQuery(graphql`
    {
      holidayHours: file(name: {eq: "anl-christmas"}){
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // check if date is greater than 03/01/2025
  const today = new Date()
  const holidayDate = new Date('2025-1-3')
  const isHoliday = today < holidayDate
  if(!isHoliday) return null
  return (
    <FullScreenOverlay isOpen={overlayOpened} onClose={() => setOverlayOpened(false)}>
      <StyledImg>
        <Img fluid={holidayHours.childImageSharp.fluid} />
      </StyledImg>
    </FullScreenOverlay>
  )
}

export default HolidayHoursOverlay
